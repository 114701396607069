@use "sass:math";
@import "@Static/css/common";

.container {
    border-radius: 24px;
    height: calc(400px - 40px);
    width: calc(100% - 40px);
    max-width: calc(100vw - 4px);
    border-radius: 24px;
    box-shadow: 0px 3px 6px #00000020;
    color: rgb(var(--template-block-text-host-theme, 34, 34, 34));
    background-color: rgb(
        var(--template-page-card-background-host-theme, 255, 255, 255)
    );
    margin: 0 20px;
}

.authorContainer {
    height: 25%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 16px;
    border-top: 2px solid getColorValue(1, (255, 91, 0), 0.2);
}

.authorImage {
    border-radius: 1000px;
    height: 3rem;
    width: 3rem;
    user-select: none;
    border: 3px solid getColorValue(1, (255, 91, 0));
}

.authorName {
    margin-left: 1rem;
    font-size: 14px;
    font-weight: 600;
    word-break: break-word;
    overflow: hidden;
}

.videoAspectRatio {
    max-width: 100%;

    $aspect-ratio: 16/9;
    $responsive-width: calc(100vw - 36px);
    $min-width: 200px;
    $max-width: 500px;

    @include aspectRatio(
        $responsive-width,
        $aspect-ratio,
        $min-width,
        $max-width
    );
}

.mediaContainer {
    width: 100%;
    height: 75%;
    position: relative;
    user-select: none;
    background-color: getColorValue(1, (0, 0, 0));
    border-radius: 24px;

    &.videoContainer {
        background-color: getColorValue(1, (0, 0, 0));
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
    }
}

.mediaOverlay {
    position: absolute;
    height: 100%;
    width: 100%;
    cursor: pointer;
    z-index: 1000;
    border-radius: 24px;
    &.videoOverlay {
        @extend .videoAspectRatio;
    }
    &.imageOverlay {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 24px;
        &.imageOverlay {
            svg {
                circle {
                    fill: getColorValue(1, (0, 0, 0));
                }
            }
        }
    }
}

.video {
    border-radius: 24px;
    object-fit: cover;

    @extend .videoAspectRatio;
    iframe {
        border-radius: 24px;
    }
    &.empty {
        color: transparent;
        text-decoration: none;
    }
}

.imageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
        to right,
        getColorValue(1, (255, 91, 0)),
        getColorValue(2, (255, 255, 255))
    );
    border-radius: 24px 24px 0 0;
}

.image {
    width: 270px;
    height: 270px;
    max-width: calc(100vw - 40px);
    max-height: calc(100vw - 40px);
    object-fit: cover;
    border-radius: 24px;
}

.quoteContainer {
    margin: 0 16px;
    $size: 48px;
    width: $size;
    height: $size;
    border-radius: 1000px;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include colorCombinationXYZ(
        (
            "bgc": (
                key: 1,
            ),
        )
    );
}
.quote {
    $size: 24px;
    width: $size;
    height: $size;
    path {
        fill: getColorValue(2, (255, 255, 255));
    }
}

.textContainer {
    width: 100%;
    height: 75%;
    padding: 16px 0;
}

.text {
    height: 75%;
    padding: 16px;
    font-size: 14px;
    font-weight: 600;
    white-space: pre-wrap;
    word-break: break-word;
}

.readMore {
    padding: 0;
}

@media only screen and (max-width: 768px) {
    .text {
        font-size: 12px;
    }
}
