@import "@Static/css/common";

$primaryColor: getColorValue(1, (255, 91, 0));
$textColor: getColorValue(3, (4, 3, 4));

// common classes
.exlyBrandingFontSize {
    font-size: 0.8rem;
}

.root {
    max-width: 500px;
}

.divider {
    margin: 30px 0;
    width: 100%;
    border-color: $textColor;
    opacity: 0.3;
}

.exlyBrandingContainer {
    @extend .exlyBrandingFontSize;
}

.exlyLogo {
    color: $primaryColor;
    font-weight: 600;
    letter-spacing: 0.5px;
    @extend .exlyBrandingFontSize;
}
