@import "@Static/css/common";

.root {
    max-width: 500px;
}

.carouselContainer {
    position: relative;
    padding: 0 0 60px;
    width: calc(100% + 40px);
    margin: 0 -20px;
    max-width: 100vw;
}

.carousel {
    z-index: 1;
}
