@import "@Static/css/common";

// theme colours
$primaryColor: getColorValue(1, (255, 91, 0));
$bgColor: rgb(var(--template-page-card-background-host-theme, (255, 255, 255)));
$textColor: getColorValue(3, (4, 3, 4));

.root {
    background-color: $bgColor;
    color: $textColor;
    padding: 12px;
    width: 100%;
    max-width: 500px;
    margin-bottom: 1rem;
    border-radius: 12px;
    box-shadow: #00000018 0 3px 6px;
    cursor: pointer;
    display: flex;
    flex-direction: column;

    transition: 0.2s ease all;

    &:hover {
        transform: translateY(-4px) scale(1.01);
        box-shadow: #0003 0 3px 6px;
    }
}

.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    .left {
        .photo {
            width: 100px;
            height: 100px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 12px;
        }
    }
    .right {
        .title {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 4px;
            line-height: 1.3;
        }

        .shortDescription {
            font-size: 0.7rem;
            opacity: 0.8;
        }
        margin-left: 12px;
    }
}

.ctaButton {
    width: 100%;
    margin-top: 12px;
    padding: 8px 0;
    transition: 0.15s ease all;
    border-radius: 12px;
    background-color: $primaryColor;

    &:hover {
        filter: brightness(1.15);
    }
}
