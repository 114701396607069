@import "@Static/css/common";

// theme colours - keep all colors(even commented ones)
// for reference for subcomponents
$color1: getColorValue(1, (255, 91, 0));
$color2: getColorValue(3, (0, 0, 0));
// $color3: getColorValue(3, (4, 3, 4));

.root {
    min-height: 100dvh;
    overflow-y: auto;
    @extend .themedScrollbar;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: $color2;
    font-weight: 500;
    font-size: 0.9rem;
}

.background {
    flex-grow: 1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    @extend .themedScrollbar;
}

.container {
    max-width: 1400px;
    width: 70vw;
    margin: auto;
    margin-top: 3rem;
    padding: 20px 1rem;
    overflow-x: hidden;
}

.sectionContainer {
    max-width: 560px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
}

.sectionAnchor {
    position: relative;
    top: -60px;
}

.sectionTitle {
    margin: 16px 0;
    font-weight: bolder;
    font-size: 1.2rem;
}

@media screen and (max-width: 780px) {
    .container {
        width: 100vw;
    }
}
