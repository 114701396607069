@import "@Static/css/common";

.profileImage {
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    background-color: #cccc;

    &.preview {
        width: 175px;
        height: 175px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
