@import "@Static/css/common";

// theme colours
$primaryColor: getColorValue(1, (255, 91, 0));
$bgColor: getColorValue(2, (255, 255, 255));
$textColor: getColorValue(3, (4, 3, 4));

// extra colours
$color3: getColorValue(2, (255, 255, 255), 0.3);

// component specific classes
.videoContainer {
    width: 100%;
    max-width: 500px;
    height: fit-content !important;
    iframe,
    video {
        border: 4px solid $color3 !important;
        border-radius: 24px;

        // The css below ensures that the video element is always
        // according to the aspect ratio, no matter the screen size
        $aspect-ratio: 16/9;
        $responsive-width: calc(100vw - 36px);
        $min-width: 200px;
        $max-width: 500px;

        @include aspectRatio(
            $responsive-width,
            $aspect-ratio,
            $min-width,
            $max-width
        );
    }
}

.getInTouchButton {
    background-color: $primaryColor;
    color: $bgColor;
    width: 100%;
    padding: 8px 0;
    transition: 0.15s ease all;
    border-radius: 12px;
    max-width: 500px;

    transition: 0.1s ease all;
    &:hover {
        filter: brightness(1.2);
    }
}
